import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Unlock({ className }) {
  return (
    <SvgIcon fill="none" className={className}>
      <rect
        x="3.5"
        y="9.14401"
        width="13"
        height="10"
        rx="1.5"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M12.9951 5.62149L11.8717 3.21228C11.0444 1.43819 8.93559 0.670637 7.1615 1.49791V1.49791C5.3874 2.32518 4.61986 4.43401 5.44713 6.2081L6.57056 8.6173"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

Unlock.propTypes = {
  className: PropTypes.string,
};

export default Unlock;
